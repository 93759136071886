<template>
  <div class="QueryDay">
    <a ref="download" style="display: none" />
    <AttendanceMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参建单位">
          <el-select
            size="small"
            v-model="currentUnit"
            placeholder="请选择参建单位"
            clearable
          >
            <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门班组">
          <el-select
            size="small"
            v-model="currentDepartment"
            placeholder="请选择部门/班组"
            clearable
          >
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.labourGroupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="统计日期">
          <el-date-picker
            v-model="currentDay"
            value-format="yyyy-MM-dd"
            :clearable="false"
            type="date"
            placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="queryDayListData">查询</el-button>
        </el-form-item>
      </el-form>
      <el-form :inline="true" v-if="currentDay != today">
        <el-form-item label="出勤状态">
          <!-- 0出勤 1未出勤 2迟到 3早退 4全勤 -->
          <span
            class="filterType"
            @click="attendanceSataus = null"
            :class="[attendanceSataus == null ? 'filterTypeActive' : '']"
            >全部</span
          >
          <span
            class="filterType"
            @click="attendanceSataus = 1"
            :class="[attendanceSataus == 1 ? 'filterTypeActive' : '']"
            >未出勤</span
          >
          <span
            class="filterType"
            @click="attendanceSataus = 2"
            :class="[attendanceSataus == 2 ? 'filterTypeActive' : '']"
            >迟到</span
          >
          <span
            class="filterType"
            @click="attendanceSataus = 3"
            :class="[attendanceSataus == 3 ? 'filterTypeActive' : '']"
            >早退</span
          >
          <span
            class="filterType"
            @click="attendanceSataus = 4"
            :class="[attendanceSataus == 4 ? 'filterTypeActive' : '']"
            >全勤</span
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportDate('page')"
        >导出当前数据</el-button
      >
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportDate('all')"
        >导出全部数据</el-button
      >
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="queryDayList" style="width: 100%">
        <el-table-column>
          <template slot-scope="scope">
            <img :src="scope.row.idPhoto" width="102px" height="126px">
          </template>
        </el-table-column>
        <el-table-column prop="consumerName" label="姓名"></el-table-column>
        <el-table-column prop="consumerNo" label="员工工号"></el-table-column>
        <el-table-column prop="groupName" label="部门/班组"></el-table-column>
        <el-table-column v-if="currentDay == today" label="出勤">
          <template slot-scope="scope">
            <i v-if="scope.row.consumerStatus == 0" class="el-icon-check"></i><!-- 0出勤 1未出勤-->
          </template>
        </el-table-column>
        <el-table-column v-if="currentDay == today" prop="consumerStatus" label="未出勤">
          <template slot-scope="scope">
            <i v-if="scope.row.consumerStatus == 1" class="el-icon-check"></i><!-- 0出勤 1未出勤-->
          </template>
        </el-table-column>
        <el-table-column v-if="currentDay != today" label="出勤状态">
          <template slot-scope="scope">
            {{getAttendanceStatus(scope.row.attendanceStatus)}}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[1, 5, 10, 20, 50]"
        :total="totals"
        @current-change="queryDayListData"
        @size-change="queryDayListData"
      >
      </el-pagination>
    </div>

  </div>
</template>

<style lang="less" scope>
.QueryDay {
  .el-form-item {
    margin-bottom: 0;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 192px;

  }
  .el-input__inner {
    height: 32px;
  }
}
</style>

<script>
import AttendanceMenu from "@/components/AttendanceMenu.vue";

export default {
  name: "QueryDay",
  components: {
    AttendanceMenu,
  },
  data() {
    return {
      currentProject: null,
      currentUnit: null,
      currentDepartment: null,
      currentDay: this.getNowFormatDay(),
      attendanceSataus: null,

      projectList: [],
      unitList: [],
      departmentList: [],

      queryDayList:[],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

    };
  },
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (val) {
      this.currentUnit = null;
      this.pageNum = 1;
      this.getUnitList(); // 加载单位列表数据
      this.queryDayListData()
    },
    currentUnit: function (val) {
      this.currentDepartment = null;
      this.pageNum = 1;
      this.getDepartmentList(); // 加载部门班组列表数据
      this.queryDayListData()
    },
    currentDepartment: function (val) {
      this.pageNum = 1;
      this.queryDayListData()
    },
    currentDay: function (val) {
      this.pageNum = 1;
      this.queryDayListData()
    },
    attendanceSataus: function (val) {
      this.pageNum = 1;
      this.queryDayListData()
    },
  },
  computed: {
    today: function () {
      return this.getNowFormatDay()
    }
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.radio = null;
      that.currentUnit = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载单位列表数据
    getUnitList: function () {
      var that = this;
      that.radio = null;
      var data = {
        companyType: "",
        companyName: "",
        address: "",
        projectId: that.currentProject,
        pageNum: 1,
        pageSize: "",
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/InquireCompany",
          data: data,
        })
        .then((res) => {
          console.log(res);
          that.unitList = res.data.depts;
          // if (res.data.depts.length) {
          //   that.currentUnit = res.data.depts[0].id;
          // }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取部门/班组列表数据
    getDepartmentList: function () {
      var that = this;
      that.radio = null;
      var data = {
        labourGroupName: "",
        projectId: that.currentProject,
        deptId: that.currentUnit,
        pageNum: 1,
        pageSize: 100,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/labourgroup/labourGroupSearch",
          data: data,
        })
        .then((res) => {
          console.log("getDepartmentList =>", res);
          that.totals = res.data.totals;
          that.departmentList = res.data.labourGroupList;
          // if (res.data.labourGroupList.length) {
          //   that.currentDepartment = res.data.labourGroupList[0].id;
          // }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 搜索按钮事件
    searchList: function () {
      this.pageNum = 1;
    },
    // 获取日统计考勤数据
    queryDayListData: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        companyId: this.currentUnit,
        groupId: this.currentDepartment,
        attendanceSataus: this.attendanceSataus,
        queryTime: this.currentDay,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgAttendanceOa/queryDayPage",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log('queryDayList=>', res);
          that.queryDayList = res.data.wgConsumerRecordList
          that.totals = res.data.totals
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取当前日期，格式YYYY-MM-DD
    getNowFormatDay: function (nowDate) {
        var char = "-";
        if(nowDate == null){
            nowDate = new Date();
        }
        var day = nowDate.getDate();
        var month = nowDate.getMonth() + 1;//注意月份需要+1
        var year = nowDate.getFullYear();
        //补全0，并拼接
        return year + char + this.completeDate(month) + char + this.completeDate(day);
    },
    //补全0
    completeDate: function (value) {
        return value < 10 ? "0"+value:value;
    },
    // 转翻译出勤状态 <!-- 0出勤 1未出勤 2迟到 3早退 4全勤 -->
    getAttendanceStatus: function (data) {
      if (!data) { return }
      var list = data.split(",")
      console.log('getAttendanceStatus =>', list);
      var result = ''
      list.forEach(item => {
        if (item == 0) { result += '出勤 ' }
        if (item == 1) { result += '未出勤 ' }
        if (item == 2) { result += '迟到 ' }
        if (item == 3) { result += '早退 ' }
        if (item == 4) { result += '全勤 ' }
      });
      return result
    },
    // 导出数据
    exportDate: function (type) {
      var that = this;
      var timestamp = new Date().getTime(); 
      var data = {
        projectId: this.currentProject,
        companyId: this.currentUnit,
        groupId: this.currentDepartment,
        attendanceSataus: this.attendanceSataus,
        queryTime: this.currentDay,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      if (!this.currentUnit) {
        data.companyId = ""
      }
      if (!this.currentDepartment) {
        data.groupId = ""
      }
      if (!this.attendanceSataus) {
        data.attendanceSataus = ""
      }
      if (type == 'all') {
        data.pageNum = 1
        data.pageSize = ""
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axios({
          method: "post",
          url: "api/admin/wgAttendanceOa/pageDownload",
          data: data,
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              return ret;
            },
          ],
        })
        .then((res) => {
          const blob = res.data;
          that.$refs.download.href = URL.createObjectURL(blob);
          that.$refs.download.download = `日统计表${timestamp}.xls`;
          that.$refs.download.click();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    }
  },
};
</script>
